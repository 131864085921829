var v1url = "https://api.pencilnews.cn/",
  M_URL = "https://m.pencilnews.cn/",
  bindUrl = "https://api.pencilnews.cn/",
  imgUrl = "https://cdn.pencilnews.cn/",
  imageview1x = "?imageView2/2/w/500/h/500/q/75",
  imageview2x = "?imageView2/2/w/1500/h/1500/q/85",
  Pc_Url = location.origin + "/",
  is_master = true;

(function () {
  let isPcTest = location.hostname.indexOf("devpc.") != -1;
  let isLocalTest = location.hostname.indexOf("localhost") != -1;

  if (isPcTest || isLocalTest) {
    (v1url = "https://devapi.pencilnews.cn/"),
    (M_URL = "https://devm.pencilnews.cn/"),
    (bindUrl = "https://devapi.pencilnews.cn/"),
    (is_master = false);
  }
})();

var UTL = {};
// UTL._token = $.jStorage.get("localToken");
UTL.object2query = function (obj) {
  var querystr = "";
  $.each(obj, function (key, val) {
    querystr += key + "=" + val + "&";
  });
  return querystr;
};

UTL.ajaxRequests = {}; //以url为key,status为值的对象,status:1 发送中，0 发送结束

UTL.ajax = function (obj) {
  //阻止连续发送url相同的请求
  if (UTL.ajaxRequests[obj.url] && UTL.ajaxRequests[obj.url] === 1) {
    $.tips("请稍后");
    return;
  }

  UTL.ajaxRequests[obj.url] = 1;

  // console.log(obj)
  var token = getCookie("token");
  var sa_obj = getCookie("sa_obj");
  var default_header = {
    devicetoken: cookie_PENCILNEWSID,
    token: token
  };
  var default_data = {
    "sa-super-property": sa_obj
  }

  var merged_header = $.extend(default_header, obj.headers);
  var merged_data = $.extend(default_data, obj.data);
  // console.log(merged_header)
  $.ajax({
    url: obj.url,
    type: obj.type,
    headers: merged_header,
    data: merged_data,
    processData: obj.processData,
    contentType: obj.contentType,
    dataType: obj.dataType,
    beforeSend: obj.beforeSend,
    success: function (data) {
      UTL.ajaxRequests[obj.url] = 0;
      // if (!obj.notCheckCode) {
      //     if (!checkAjaxCode(data)) {
      //         return;
      //     }
      // }
      obj.success(data);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR, textStatus, errorThrown);
    }
  });
};

//获取url中的参数
UTL.getUrlParam = function (name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
};

UTL.getSearchParam = function (name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参
  if (r != null) return (decodeURI(r[2]));
  return null; //返回参数值
};

// console.log(UTL.object2query({'a':1,'b':2}))

UTL.getBrowserInfo = function () {
  var agent = navigator.userAgent.toLowerCase();

  var regStr_ie = /msie [\d.]+;/gi;
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStr_saf = /safari\/[\d.]+/gi;

  var info = {
    browser: "",
    app: "",
    version: ""
  };

  //IE
  if (agent.indexOf("msie") > 0) {
    info.browser = agent.match(regStr_ie);
    info.app = "IE";
    // return agent.match(regStr_ie);
  }

  //firefox
  // if (agent.indexOf("firefox") > 0) {
  //     return agent.match(regStr_ff);
  // }

  //Chrome
  if (agent.indexOf("chrome") > 0) {
    info.browser = agent.match(regStr_chrome);
    info.app = "Chrome";
    // return agent.match(regStr_chrome);
  }

  // //Safari
  // if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
  //     return agent.match(regStr_saf);
  // }
  info.version = (info.browser + "").replace(/[^0-9.]/gi, "");
  return info;
};

UTL.showUpgradeIEtips = function () {
  // var mb = UTL.getBrowserInfo();
  // console.log(mb)
  // if (mb.app == "IE" && parseFloat(mb.version) < 9) {
  var ieTipHtml =
    '<div class="ie_tip">' +
    '<div class="content">' +
    "<p>您使用的浏览器版本过低，可能有安全风险，您可以选择</p>" +
    '<a target="_blank" href="http://www.google.cn/chrome/browser/desktop/index.html">Chrome浏览器</a>' +
    '<a target="_blank" href="https://support.microsoft.com/zh-cn/help/17621/internet-explorer-downloads">IE浏览器最新版</a>' +
    "</div>" +
    "</div>";

  //加入html
  $(ieTipHtml).insertAfter("footer");

  //点击背景取消
  $(".ie_tip").on("click", function () {
    $(".ie_tip").hide();
  });
  $(".ie_tip").on("click", ".content", function (e) {
    e.stopPropagation();
  });
  // }
};

// UTL.showUpgradeIEtips()

//时间格式转换
UTL.timeFormatConvert = function (time) {
  var timer = new Date(time);
  var currentTime = new Date();
  var m = currentTime - timer;
  if (m < 3600000) {
    m = m / 60000;
    m = Math.floor(m);
    if (m <= 0) {
      m = 1;
    }
    return m + "分钟前";
  } else if (m < 86400000) {
    m = m / 3600000;
    m = Math.floor(m);
    if (m == 0) {
      m = 1;
    }
    return m + "小时前";
  } else {
    m = time.split(" ")[0];
    return m;
  }
};

function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

function setCookie(name, value) {
  document.cookie = name + "=" + value + "; path=/";
}

function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie =
    name + "=" + cval + ";expires=" + exp.toGMTString() + "; path=/"; //name + "="+cval+";expires="+exp.toGMTString()+"; path=/";
}

function getViewedBarrager() {
  var local_likes = getCookie("viewd_Barrager");
  return local_likes ? JSON.parse(local_likes) : [];
}

//设置浏览过的barrager到cookie
function setViewedBarrager(id) {
  var barrager = getViewedBarrager();
  barrager.push(id);
  setCookie("viewd_Barrager", JSON.stringify(barrager));
}

function delViewedBarrager() {
  console.log("clear");
  delCookie("viewd_Barrager");
}

var cookie_PENCILNEWSID = getCookie("PENCILNEWSID");
//节流函数，用于scroll resize事件
function throttle(method, context) {
  clearTimeout(method.tId);
  // console.log('cleat')
  method.tId = setTimeout(function () {
    method.call(context);
  }, 50);
}

//判断是否为手机
function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

//判断是否是手机（不包括pad）
function isPhone() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  var flag = false;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = true;
      break;
    }
  }
  return flag;
}

//判断iso 还是 Android
var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
//判断是否微信浏览器
var isWeiXin = !!u.match(/MicroMessenger/i);

// 验证手机号为数字且不为空
function validPhone(obj) {
  var phoneNumber = obj.val();
  var pattern = /^1[3456789]\d{9}$/;
  if (!pattern.test(phoneNumber) || phoneNumber == "") {
    showWarning("请输入正确的手机号！");
    return false;
  } else {
    return true;
  }
}
/*倒计时(间隔1秒)
 obj 倒计时对象
 count 时间
 str 倒计时结束后，obj显示的文字
*/
function makeCountDown(obj, count, str) {
  obj.attr("disabled", "true");
  var timer = null;
  timer = setInterval(function () {
    count--;
    obj.html(count);
    if (count == 0) {
      clearInterval(timer);
      obj.removeAttr("disabled");
      obj.html("获取验证码");
    }
  }, 1000);
}

function checkInterfaceData(data) {
  if (data.code == 1000) {
    return true;
  } else if (data.code == -1) {
    $("#login_bg").show();
    $("#sign_up_bg")
      .css({
        left: "50%"
      })
      .show();
    $(".sign_in_con")
      .show()
      .css({
        opacity: "1"
      });
    $(".sign_up_con").hide();

    return false;
  } else {
    showWarning(data.message);
    return false;
  }
}

function parseQuery(query) {
  var reg = /([^=&\s]+)[=\s]*([^=&\s]*)/g;
  var obj = {};
  while (reg.exec(query)) {
    obj[RegExp.$1] = RegExp.$2;
  }
  return obj;
}

function checkInterfaceCode(data) {
  switch (data.code) {
    case -3:
      showWarning("服务器请求失败");
      return false;
    case -2:
      showWarning("权限认证失败");
      return false;
    case -1:
      showPCLoginForm();
      return false;
    case 0:
      showWarning(data.message);
      return false;
    case 1000:
      return true;
    case 1001:
      showWarning(data.message);
      return false;
    case 1002:
      showWarning("注册失败");
      return false;
    case 1003:
      showWarning("校验失败");
      return false;
    case 1004:
      showWarning("未找到");
      return false;
    case 1005:
      showWarning("请求方式错误");
      return false;
    case 1006:
      showWarning("已经做过该操作，请勿重复请求");
      return false;
    case 1007:
      showWarning("未做过该项请求，取消失败");
      return false;
  }
}

function checkLoginEffect(data) {
  switch (data.code) {
    case -3:
      showWarning("服务器请求失败");
      return false;
    case -2:
      showWarning("权限认证失败");
      return false;
    case -1:
      $.jStorage.flush();
      location.href = "/";
      return false;
    case 0:
      showWarning(data.message);
      return false;
    case 1000:
      return true;
    case 1001:
      showWarning(data.message);
      return false;
    case 1002:
      showWarning("注册失败");
      return false;
    case 1003:
      showWarning("校验失败");
      return false;
    case 1004:
      showWarning("未找到");
      return false;
    case 1005:
      showWarning("请求方式错误");
      return false;
    case 1006:
      showWarning("已经做过该操作，请勿重复请求");
      return false;
    case 1007:
      showWarning("未做过该项请求，取消失败");
      return false;
  }
}

//获取url参数
function getUrlParam(name) {
  var search = document.location.search;
  var pattern = new RegExp("[?&]" + name + "=([^&]+)", "g");
  var matcher = pattern.exec(search);
  var items = null;
  if (null != matcher) {
    try {
      items = decodeURIComponent(decodeURIComponent(matcher[1]));
    } catch (e) {
      try {
        items = decodeURIComponent(matcher[1]);
      } catch (e) {
        items = matcher[1];
      }
    }
  }
  return items;
}

//弹出PC端登录框
function showPCLoginForm() {
  $("#login_bg").show();
  $("#regist_container").hide();
  $("#forget_pwd_container").hide();
  $("#login_container").show();
  $("body").addClass("modal-open");
}

UTL.showPCLoginForm = function () {
  $("#login_bg").show();
  $("#regist_container").hide();
  $("#forget_pwd_container").hide();
  $("#login_container").show();
  $("body").addClass("modal-open");
};

//弹出PC端注册框
function showPCRegistForm() {
  $("#login_bg").show();
  $("#login_container").hide();
  $("#forget_pwd_container").hide();
  $("#regist_container").show();
  $("body").addClass("modal-open");
}

function showPCForgetPwdForm() {
  $("#login_bg").show();
  $("#login_container").hide();
  $("#regist_container").hide();
  $("#forget_pwd_container").show();
  $("body").addClass("modal-open");
}

function validTokenEffective(token, fun) {
  $.get({
    url: v1url + "user/profile",
    headers: {
      token: token,
      devicetoken: cookie_PENCILNEWSID
    },
    success: function (data) {
      if (data.code != 1000) {
        fun();
        $.jStorage.flush();
      } else {
        // 处理首页认证按钮显示问题
        $.jStorage.set("tempCertState", data.data.profile.cert.state);
        handleCertBtnHide(data.data.profile.cert.state)
      }
    }
  });
}

function handleCertBtnHide(certState) {
  if (certState == 1) {
    $(".js-certbtn").html(
      `<a href="/projectlist" target="_blank">
      <span class="btn_center">查看更多项目</span>
      </a>`);
  } else {
    $(".js-certbtn").html(`<a href="/datacert">
        <span class="btn_left">立即认证</span>
    </a>
    <a href="/projectlist" target="_blank">
        <span class="btn_right">查看更多项目</span>
    </a>`);
  }
}

function reLoginOnUc() {
  $.jStorage.flush();
  $("#login_on1").hide();
  $(".login_sigin_con").show();
  showPCLoginForm();
}

//未登录情况下提示登录(PC & M)
function showLoginPage() {
  if (IsPC()) {
    showPCLoginForm();
  } else {
    $.jStorage.set("localLastUrl", location.href);
    location.href = "/login_phone";
  }
}

//验证手机号
function validPhoneNumber(num) {
  var reg = /^0?1[3|4|5|8][0-9]\d{8}$/;
  if (reg.test(num)) {
    return true;
  } else {
    return false;
  }
}

//验证内容是否含有数字,含有数字返回true，不含数字返回false
function validIsHasNumber(str) {
  var reg = /^\D*$/g;
  if (reg.test(str)) {
    return false;
  } else {
    return true;
  }
}

//验证邮箱格式是否正确
function validIsEmail(email) {
  var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  if (myreg.test(email)) {
    return true;
  } else {
    return false;
  }
}

function showCheckedPassedIcon(inputObjId) {
  $("#" + inputObjId).removeClass("checked_failed");
  $("#" + inputObjId).addClass("checked_passed");
  $("#" + inputObjId + " img").attr("src", "/imgs/uc_icons/icon_pc_check.png");
}

function showCheckedFailedIcon(inputObjId) {
  $("#" + inputObjId).removeClass("checked_passed");
  $("#" + inputObjId).addClass("checked_failed");
  $("#" + inputObjId + " img").attr("src", "/imgs/uc_icons/icon_pc_x.png");
}

//显示加载中的动画
function showLoadingGif() {
  if ($("body .util-loading").length > 0) {
    $(".util-loading").fadeIn();
    return;
  }

  var LoadingHTML =
    '<div class="util-loading"><div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div></div>';

  $("body").append(LoadingHTML);
  $(".util-loading").fadeIn();
}

//关闭加载中的动画
function closeLoadingGif() {
  //$(".util-loading").remove();
  $(".util-loading").fadeOut();
}

//时间格式转换
function timeFormatConvert(time) {
  var timer = new Date(time.replace(/-/g, "/"));
  var currentTime = new Date();
  var m = currentTime - timer;
  if (m < 3600000) {
    m = m / 60000;
    m = Math.floor(m);
    if (m <= 0) {
      m = 1;
    }
    return m + "分钟前";
  } else if (m < 86400000) {
    m = m / 3600000;
    m = Math.floor(m);
    if (m == 0) {
      m = 1;
    }
    return m + "小时前";
  } else {
    m = time.split(" ")[0];
    return m;
  }
}

// 统计点击广告的位置
function getAdevertisementPosition(position) {
  switch (position) {
    case 'pcIndexTop':
      position = 'PC首页顶部';
      break;
    case 'pcIndexRight':
      position = 'PC首页右部';
      break;
    case 'pcArticleBottom':
      position = 'PC文章页底部';
      break;
    case 'mIndexTop':
      position = 'M首页顶部';
      break;
    case 'mArticleTop':
      position = 'M文章页顶部';
      break;
    default:
      position = '其它';
      break;
  }
  sa.track('clickAdvertisement', {
    "$lib_detail": '点击广告位##点击事件##util.js##620',
    "position": position
  });
  // console.log('统计完毕！')
}

UTL.checkUaToMobile = function (m_url) {
  if (isiOS || isAndroid) {
    location.href = m_url;
  }
};

UTL.reachBottom = function (cb) {
  var isBottom = false;
  $(window).on("scroll", function () {
    var dH = document.body.scrollHeight,
      scrollH =
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      window.scrollY,
      windowH = document.documentElement.clientHeight;
    // console.log(scrollH,  windowH ,dH)
    // console.log(scrollH + windowH -dH,isBottom)
    if (scrollH + windowH - dH > -400) {
      if (isBottom) return;
      // console.log('dibu')
      cb && cb();
      isBottom = true;
    } else {
      isBottom = false;
    }
  });
};

toastr.options = {
  positionClass: "toast-top-center",
  timeOut: 1500
};

UTL.getWxConfig = function (cb) {
  var url = encodeURIComponent(location.href);
  $.ajax({
    // url: `${v1url}${api.wechatConfig}${url}`,
    url: v1url + "wechat/jsapi_config?url=" + url,
    type: "GET",
    dataType: "json",
    success: function (data) {
      if (data.code == 1000) {
        cb && cb(data);
      } else {
        toastr.error(data.message);
      }
    },
    error: function () {}
  });
};

UTL.httpWebsite = function (str) {
  if (!str) {
    return;
  }
  str = str.replace(/^\s*/, "");
  return /http/.test(str) ? str : str ? "http://" + str : "javascript;";
};

// 例： 2019-02-25 11:20:20 ===》 2019年02月25日
UTL.formatDate = function (str) {
  str = str.split(" ")[0];
  return (
    str.split("-")[0] +
    "年" +
    str.split("-")[1] +
    "月" +
    str.split("-")[2] +
    "日"
  );
};

// 例： 2019-02-25 11:20:20 ===》 2019.02.25
UTL.formatDateV2 = function (str) {
  str = str.split(" ")[0];
  return (
    str.split("-")[0] +
    "." +
    str.split("-")[1] +
    "." +
    str.split("-")[2]
  );
}

UTL.createTimeStamp = function () {
  return new Date().getTime();
};

UTL.randomString = function (len) {
  len = len || 32;
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

// 获取城市列表数据
UTL.getDistricts = function (cb) {
  //先从缓存里面取，有则不发请求
  if( JSON.parse(sessionStorage.getItem("_districts_data"))){
    UTL._districts_data = JSON.parse(sessionStorage.getItem("_districts_data"));
    cb && cb(UTL._districts_data)
    return;
  }
  var that = this;
  $.ajax({
    url: v1url + "u-g-c-project/districts",
    type: "GET",
    headers: {
      token: submitProject._token
    },
    success: function (data) {
      if (data.code === -1) {
        reLoginOnUc();
        return;
      } else if (data.code === 1000) {
        UTL._districts_data = data.data;
        sessionStorage.setItem("_districts_data", JSON.stringify(data.data));
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    }
  });
  return this;
};

// 获取行业列表数据
UTL.getIndustryList = function (cb) {
  //先从缓存里面取，有则不发请求
  if( JSON.parse(sessionStorage.getItem("_industry_list_data"))){
    UTL._industry_list_data = JSON.parse(sessionStorage.getItem("_industry_list_data"));
    cb && cb(UTL._industry_list_data)
    return;
  }
  var that = this;
  $.ajax({
    url: v1url + "u-g-c-project/industry-list",
    type: "GET",
    headers: {
      token: submitProject._token
    },
    success: function (data) {
      if (data.code === -1) {
        reLoginOnUc();
        return;
      } else if (data.code === 1000) {
        UTL._industry_list_data = data.data;
        sessionStorage.setItem(
          "_industry_list_data",
          JSON.stringify(data.data)
        );
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    }
  });
  return this;
};

// 获取融资类型列表
UTL.getFinancialTypeList = function (cb) {
  //先从缓存里面取，有则不发请求
  if( JSON.parse(sessionStorage.getItem("_financial_list_data"))){
    UTL._financial_list_data = JSON.parse(sessionStorage.getItem("_financial_list_data"));
    cb && cb(UTL._financial_list_data)
    return;
  }
  var that = this;
  $.ajax({
    url: v1url + "u-g-c-round/financial-type",
    type: "GET",
    headers: {
      token: submitProject._token
    },
    success: function (data) {
      if (data.code === -1) {
        reLoginOnUc();
        return;
      } else if (data.code === 1000) {
        UTL._financial_list_data = data.data;
        sessionStorage.setItem(
          "_financial_list_data",
          JSON.stringify(data.data)
        );
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    }
  });
  return this;
};

// 获取公司规模列表
UTL.getCompanyScalList = function (cb) {
  //先从缓存里面取，有则不发请求
  if( JSON.parse(sessionStorage.getItem("_companyScale_list_data"))){
    UTL._companyScale_list_data = JSON.parse(sessionStorage.getItem("_companyScale_list_data"));
    cb && cb(UTL._companyScale_list_data)
    return;
  }
  var that = this;
  $.ajax({
    url: v1url + "common/company-scale",
    type: "GET",
    headers: {
      token: submitProject._token
    },
    success: function (data) {
      if (data.code === -1) {
        reLoginOnUc();
        return;
      } else if (data.code === 1000) {
        UTL._companyScale_list_data = data.data;
        sessionStorage.setItem(
          "_companyScale_list_data",
          JSON.stringify(data.data)
        );
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    }
  });
  return this;
};

// 获取字符串字节数
UTL.getStrBytes = function (strings) {
  var bytesCount = 0;
  var str = strings || "";
  for (var i = 0; i < str.length; i++) {
    var c = str.charAt(i);
    if (/^[\u0000-\u00ff]$/.test(c)) {
      //匹配双字节
      bytesCount += 1;
    } else {
      bytesCount += 2;
    }
  }
  return bytesCount;
};

// 获取图片的真实尺寸
UTL.getImgSize = function (img) {
  var imgSize = {};
  var maxLength = 0;
  if (img.naturalWidth && img.naturalHeight) {
    imgSize.width = img.naturalWidth;
    imgSize.height = img.naturalHeight;
  } else {
    var image = new Image();
    image.src = img.src;
    imgSize.width = image.width;
    imgSize.height = image.height;
  }
  if (imgSize.width > imgSize.height) {
    maxLength = imgSize.width;
    if (maxLength > 1024) {
      imgSize.width = 1024;
      imgSize.height = (imgSize.width / maxLength) * imgSize.height;
    }
  } else {
    maxLength = imgSize.height;
    if (maxLength > 1024) {
      imgSize.height = 1024;
      imgSize.width = (imgSize.height / maxLength) * imgSize.width;
    }
  }
  return imgSize;
};

// 获取用户信息
UTL.getUserProfile = function (token, cb) {
  $.get({
    url: v1url + "user/profile",
    headers: {
      token: token,
      devicetoken: cookie_PENCILNEWSID
    },
    success: function (data) {
      if (data.code != 1000) {
        fun();
        $.jStorage.flush();
      } else {
        cb && cb(data.data);
      }
    }
  });
};

// 获取用户认证信息
UTL.getuserInformationData = function (token, cb) {
  $.ajax({
    url: v1url + "u-g-c-project/detail-user-information",
    type: "GET",
    headers: {
      token: token
    },
    success: function success(data) {
      if (data.code === -1) {
        reLoginOnUc();
        return;
      } else if (data.code === 1000) {
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    }
  });
}

// 字符串处理：长字符串 ==> 短字符串...
// 例：今天的天气太好了，很适合旅行。==> 今天的天气...
/*
  入参：待处理的字符串：longStr
       限制的长度：len
  出参：处理后的字符串
*/
UTL.getShortStr = function (longStr, len) {
  var longStr = longStr || '';
  if (typeof (longStr) == "string" && longStr.length > len) {
    longStr = longStr.substring(0, len);
    longStr = longStr + "..."
  }
  return longStr;
}

/* 图片预览 */
UTL.previewImg = function (imgNode) {
  $(function () {
    $(imgNode).mouseover(function () {
      $(this).css("cursor", "pointer");
    });

    $(imgNode).click(function () {
      var _this = $(this); //将当前的pimg元素作为_this传入函数    
      var src = _this.attr("src"); //获取当前点击的pimg元素中的src属性    
      $('.js-showImg').attr('display', 'block');
      $('.js-bigImg').attr("src", src); //设置#bigimg元素的src属性    
      $('.js-showImg').fadeIn("fast");

      $('.js-showImg').click(function () { //再次点击淡出消失弹出层    
        $(this).fadeOut("fast");
      });
    });
  });

}

/*
  wait:最初的提交时间，例如2018-01-31 18:23:23
  times: 设定的时间限制，15天
  return: 返回剩余时间，若剩余时间为负数返回false
*/
UTL.remainingTime = (wait, times) => {
  // console.log(wait,times)
  if (!!wait) {
    var now = new Date().getTime();
    var remaining =
      times * 60 * 60 * 24 * 1000 -
      (now - Date.parse(wait.replace(/-/gi, "/")));
    // 测试1天切换为一分钟
    // var remaining =
    // times * 60 * 1000 -
    // (now - Date.parse(wait.replace(/-/gi, "/")));
    // console.log(remaining /(60*60*1000))
    var h = Math.ceil(remaining / (60 * 60 * 1000));
    // console.log(h)
    var day = parseInt(h / 24);
    var hours = h % 24;
    if (remaining <= 0) {
      return false;
    } else {
      if (day > 0) {
        if (hours == 0) {
          return day + "天";
        } else {
          return day + "天" + hours + "小时";
        }
      } else {
        return hours + "小时";
      }
    }
  } else {
    return false;
  }
};
/* 
  功能：获取滚动条距离正文顶部的距离、距离可视窗口底部的距离
  参数：key: top|buttom(默认是top)
*/
UTL.getScrollBarPosition = (key = "top") => {
  // 滚动条长度
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  }
  // console.log("滚动条长度：", clientHeight)

  // 滚动条距离正文顶部距离
  let scrollTop = 0;
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop;
  } else if (document.body) {
    scrollTop = document.body.scrollTop;
  }
  // console.log("距离顶部高度：", scrollTop);

  // 滚动条距离可视窗口底部的高度
  let scrollButtom = document.body.scrollHeight - scrollTop - clientHeight;
  // console.log("距离低部高度：", scrollButtom);
  if (key == "top") {
    return scrollTop;
  } else if (key == "buttom") {
    return scrollButtom;
  } else {
    return 0;
  }
}

UTL.extractCity = function (addr) {
  if (!addr) {
    return;
  }
  var rules = ["北京", "上海", "广州", "深圳", "杭州", "成都", "武汉", "郑州", "大连", "长春", "南京", "重庆", "天津", "长沙", "西安"];
  for (var i = 0, l = rules.length; i < l; i++) {
    if (addr.indexOf(rules[i]) > -1) {
      return rules[i];
    }
  }
  return "未知";
};